import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const API_ENDPOINT = 'https://j5yb8sv65c.execute-api.us-east-1.amazonaws.com/prod_esp/register';

  // Validar la contraseña
  const validatePassword = (password) => {
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const minLength = password.length >= 8;
    return hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase && minLength;
  };

  // Manejar el registro del usuario
  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setRegistrationSuccess(false);
    setLoading(true);

    if (!validatePassword(password)) {
      setErrorMessage(
        'La contraseña debe tener al menos 8 caracteres y contener: 1 número, 1 carácter especial, 1 letra mayúscula y 1 letra minúscula.'
      );
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();

      if (response.ok) {
        setRegistrationSuccess(true);
      } else {
        setErrorMessage(result.message || 'Error al registrar usuario');
      }
    } catch (error) {
      setErrorMessage('Error al conectar con el servidor');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Registro</h2>
      {registrationSuccess ? (
        <div style={{ color: 'green' }}>
          Registro exitoso. Por favor, revisa tu correo electrónico para completar el proceso.
        </div>
      ) : (
        <form onSubmit={handleRegister}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Contraseña"
            required
          />
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <button type="submit" disabled={loading}>
            {loading ? 'Cargando...' : 'Registrarse'}
          </button>
        </form>
      )}
      
    </div>
  );
}

export default Register;