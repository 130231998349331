import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './DownloadTextPage.css';
import logo from './texto-extraido-escaneado.png';
import FooterEmail from './FooterEmail';

const DownloadTextPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { signedUrl, email, fileKey } = location.state || {};
  
  const isLoggedIn = !!localStorage.getItem('userEmail'); // Verificar si el usuario está logueado

  // Función para registrar el historial de descarga
  const registerDownloadHistory = async () => {
    if (!email || !fileKey) {
      console.error('Faltan datos para registrar el historial:', { email, fileKey });
      return; // No procede si faltan datos
    }

    try {
      const response = await fetch('https://tvd3oah3x2x24grrffwafl4ive0cmyaj.lambda-url.us-east-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,    
          key: fileKey,
          bucket: "pdfextracttools"
        })
      });

      if (!response.ok) {
        throw new Error('Error al registrar el historial');
      }

      const data = await response.json();
      console.log('Historial registrado:', data);
    } catch (error) {
      console.error('Error al registrar el historial:', error);
    }
  };

  useEffect(() => {
    if (signedUrl) {
      registerDownloadHistory();
    }
  }, [signedUrl]);

  // Función de descarga y redirección condicional
  const handleDownload = () => {
    if (isLoggedIn) {
      navigate('/paymentgateway'); // Redirige a PaymentGateway si el usuario está logueado
    }
  };

  return (
    <div className="download-container">
      <h1>Texto extraído</h1>
      {signedUrl ? (
        <a href={signedUrl} download className="download-button" onClick={handleDownload}>
          Haz click para descargar tu texto extraído!
        </a>
      ) : (
        <p>Cargando...</p>
      )}
      {!isLoggedIn && (
        <button className="back-button" onClick={() => navigate('/')}>
          Volver al inicio
        </button>
      )}
      <img src={logo} alt="TacPDF Logo" className="logo2" />
      <FooterEmail />
    </div>
  );
};

export default DownloadTextPage;