// src/components/FooterEmail.jsx
import React from 'react';

function FooterEmail() {
  const userEmail = localStorage.getItem('userEmail'); // Obtiene el email de localStorage

  if (!userEmail) return null; // Si no hay email en localStorage, no muestra nada

  return (
    <footer style={{
      position: 'fixed',
      bottom: 0,
      right: 0,
      padding: '8px',
      fontSize: '12px',
      color: 'gray',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    }}>
      Sesión iniciada para: {userEmail}
    </footer>
  );
}

export default FooterEmail;