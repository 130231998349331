import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import FooterEmail from './FooterEmail';

function ExtractTextPage() {
  const [pdfPreview, setPdfPreview] = useState(null); // Estado para mostrar la vista previa del PDF
  const [loading, setLoading] = useState(false); // Estado de carga
  const navigate = useNavigate();
  const location = useLocation();
  const { fileURL, email } = location.state || {}; // Ahora se utiliza el correo en lugar de userId
  

  useEffect(() => {
    if (!fileURL) {
      console.log('No se encontró fileURL en el estado, redirigiendo a la página principal...');
      navigate('/'); // Redirige a la página principal si no hay fileURL
    } else {
      console.log('fileURL encontrado en el estado:', fileURL);
      console.log('Email encontrado en el estado:', email);
      setPdfPreview(fileURL); // Muestra la vista previa del PDF

      if (email) {
        // Almacena el email en localStorage si está disponible
        localStorage.setItem('userEmail', email);
    }
  }

  }, [fileURL, email, navigate]);

  const handleExtractText = async () => {
    setLoading(true); // Activa el estado de carga
    try {
      console.log('Enviando solicitud para extraer texto del archivo:', fileURL.split('/').pop());

      // Envía la solicitud a la Lambda con el bucket, key, y el email del usuario
      const response = await axios.post('https://dhwaqifkkdpkyglcdkfr6x73fa0yymta.lambda-url.us-east-1.on.aws/', {
        bucket: 'pdfextracttools',
        key: fileURL.split('/').pop(), // Extrae el nombre del archivo del URL
        email: email // Pasa el correo en la solicitud
      });

      console.log('Respuesta completa del servidor:', response);
      const { data } = response;

      // Si la respuesta incluye una URL firmada, navega a la página de descarga
      if (data.signedUrl) {
        console.log('URL firmada obtenida:', data.signedUrl);
        navigate('/downloadtextpage', { state: { signedUrl: data.signedUrl, email, fileKey: fileURL.split('/').pop() } }); // Pasa el correo y la clave del archivo
      } else {
        console.error('No se encontró una URL firmada en la respuesta del servidor.');
        alert('Hubo un problema al obtener la URL firmada.');
      }
    } catch (error) {
      console.error('Error al extraer el texto:', error);
      console.error('Detalles del error:', error.response ? error.response.data : 'No se proporcionaron detalles del error');
      alert('Hubo un error al extraer el texto.');
    } finally {
      setLoading(false); // Desactiva el estado de carga
    }
  };

  return (
    <div>
      {pdfPreview && (
        <div>
          <h2>Previsualización del PDF</h2>
          <object data={pdfPreview} type="application/pdf" width="100%" height="500px">
            <p>
              The PDF file cannot be displayed in this browser. You can{' '}
              <a href={pdfPreview}>download it here.</a>.
            </p>
          </object>
          <button onClick={handleExtractText} disabled={loading}>
            {loading ? 'Extrayendo el texto...' : 'Extraer texto'}
          </button>
        </div>
      )}
      <FooterEmail />
    </div>
  );
}

export default ExtractTextPage;